import { unifyUrl } from '../../../../includes/assets/js/modules/dpitUtilsInc';

const KEY_4_VISITED_DATA = 'dp_intro_tours.visited_urls';

export default class DpitUserVisitCounter {
	static processVisit(mainCfg) {
		DpitUserVisitCounter._processVisit(mainCfg, KEY_4_VISITED_DATA);
		//if (mainCfg.currentUserId != 0) DpitUserVisitCounter._processVisit(mainCfg, 'dp_intro_tours.visited_urls_logged_in');
	}

	static _processVisit(mainCfg, vclsid) {
		const visitedData = DpitUserVisitCounter._getVisitCount(vclsid);
		visitedData.obj[visitedData.url] = visitedData.count + 1;
		if (mainCfg.dpDebugEn) console.log('[dpit] VISIT COUNTER:', visitedData.url, visitedData.count + 1);
		localStorage.setItem(vclsid, JSON.stringify(visitedData.obj));
	}

	static _getVisitCount(vclsid) {
		const currentUrl = new URL(window.location);
		const currentUrlUnified = unifyUrl(currentUrl.pathname);
		const visitedJSON = localStorage.getItem(vclsid);
		let visitedObj = {};
		if (visitedJSON && isNaN(visitedJSON)) {
			try {
				visitedObj = JSON.parse(visitedJSON) || {};
			} catch (exc) {
				console.error(exc);
			}
		}
		let visitedThisPage = parseInt(visitedObj[currentUrlUnified]);

		if (isNaN(visitedThisPage)) {
			visitedThisPage = 0;
		}
		return { count: visitedThisPage, obj: visitedObj, url: currentUrlUnified };
	}

	static getVisitCount(mainCfg) {
		if (mainCfg.currentUserId != 0) {
			// server based data
			const visitedThisPage = parseInt(mainCfg.loggedInVisitCnt);
			return isNaN(visitedThisPage) ? 0 : visitedThisPage;
		} else {
			// local storage based data
			return DpitUserVisitCounter._getVisitCount(KEY_4_VISITED_DATA).count;
		}
	}
}
