
/**
 * DpitHooks object
 *
 * This object needs to be declared early so that it can be used in code.
 * Preferably at a global scope.
 */
if (!window.DpitHooks) window.DpitHooks = {};
//DpitHooks = window.DpitHooks || {}; // Extend Hooks if exists or create new Hooks object.
DpitHooks.actions = DpitHooks.actions || {}; // Registered actions
DpitHooks.filters = DpitHooks.filters || {}; // Registered filters


DpitHooks.init = function (dpDebugEn) {
	DpitHooks.dpDebugEn = dpDebugEn;
	if (dpDebugEn) console.log('[dpit] INIT DpitHooks');
};
/**
  * Add a new Action callback to DpitHooks.actions
  *
  * @param tag The tag specified by doAction()
  * @param callback The callback function to call when do_action() is called
  * @param priority The order in which to call the callbacks. Default: 10 (like WordPress)
  */
DpitHooks.addAction = function (tag, callback, priority) {
	if (typeof priority === 'undefined') {
		priority = 10;
	}

	// If the tag doesn't exist, create it.
	DpitHooks.actions[tag] = DpitHooks.actions[tag] || [];
	DpitHooks.actions[tag].push({ priority, callback });
};

/**
  * Add a new Filter callback to DpitHooks.filters
  *
  * @param tag The tag specified by applyFilters()
  * @param callback The callback function to call when apply_filters() is called
  * @param priority Priority of filter to apply. Default: 10 (like WordPress)
  */
DpitHooks.addFilter = function (tag, callback, priority) {
	if (typeof priority === 'undefined') {
		priority = 10;
	}

	// If the tag doesn't exist, create it.
	DpitHooks.filters[tag] = DpitHooks.filters[tag] || [];
	DpitHooks.filters[tag].push({ priority, callback });
};

/**
  * Remove an Action callback from DpitHooks.actions
  *
  * Must be the exact same callback signature.
  * Warning: Anonymous functions can not be removed.
  * @param tag The tag specified by doAction()
  * @param callback The callback function to remove
  */
DpitHooks.removeAction = function (tag, callback) {
	DpitHooks.actions[tag] = DpitHooks.actions[tag] || [];

	DpitHooks.actions[tag].forEach((filter, i) => {
		if (filter.callback === callback) {
			DpitHooks.actions[tag].splice(i, 1);
		}
	});
};

/**
  * Remove a Filter callback from DpitHooks.filters
  *
  * Must be the exact same callback signature.
  * Warning: Anonymous functions can not be removed.
  * @param tag The tag specified by applyFilters()
  * @param callback The callback function to remove
  */
DpitHooks.removeFilter = function (tag, callback) {
	DpitHooks.filters[tag] = DpitHooks.filters[tag] || [];

	DpitHooks.filters[tag].forEach((filter, i) => {
		if (filter.callback === callback) {
			DpitHooks.filters[tag].splice(i, 1);
		}
	});
};

/**
  * Calls actions that are stored in DpitHooks.actions for a specific tag or nothing
  * if there are no actions to call.
  *
  * @param tag A registered tag in DpitHooks.actions
  * @param args Optional JavaScript object to pass to the callbacks
  */
DpitHooks.doAction = function (tag, args) {
	const actions = [];
	if (DpitHooks.dpDebugEn) console.log('[dpit] API Action > Tag:', tag, ', Subscribed:', DpitHooks.actions[tag] ? DpitHooks.actions[tag].length : 0, ', Args:', args);
	if (typeof DpitHooks.actions[tag] !== 'undefined' && DpitHooks.actions[tag].length > 0) {
		DpitHooks.actions[tag].forEach((hook) => {
			actions[hook.priority] = actions[hook.priority] || [];
			actions[hook.priority].push(hook.callback);
		});

		actions.forEach((hooks) => {
			hooks.forEach((callback) => {

				callback(args);
			});
		});
	}
};

/**
  * Calls filters that are stored in DpitHooks.filters for a specific tag or return
  * original value if no filters exist.
  *
  * @param tag A registered tag in DpitHooks.filters
  * @param args Optional JavaScript object to pass to the callbacks
  */
DpitHooks.applyFilters = function (tag, value, args) {
	const filters = [];
	let res = value;
	if (DpitHooks.dpDebugEn) console.log('[dpit] API Filter > Tag:', tag, ', Subscribed count:', DpitHooks.filters[tag] ? DpitHooks.filters[tag].length : 0, ', Args:', args);
	if (typeof DpitHooks.filters[tag] !== 'undefined' && DpitHooks.filters[tag].length > 0) {
		DpitHooks.filters[tag].forEach((hook) => {
			filters[hook.priority] = filters[hook.priority] || [];
			filters[hook.priority].push(hook.callback);
		});

		filters.forEach((hooks) => {
			hooks.forEach((callback) => {
				res = callback(res, args);
			});
		});
	}

	return res;
};

export default DpitHooks;
