import Dpit from './modules/Dpit';
import DpitUserVisitCounter from './modules/DpitUserVisitCounter';
import DpitHooks from '../../../includes/assets/js/modules/DpitHooks';
import { processDisableOnLoadCookie } from './modules/dpitUtilsPublic';

DpitHooks.init(window.dpIntroTourPublicConfig.dpDebugEn);

jQuery(window).on('load', () => {
	// jQuery(document).ready(function () {
	window.$ = jQuery;
	if (window.dpIntroTourPublicConfig.dpDebugEn) console.log('[dpit] INIT FREE config:', window.dpIntroTourPublicConfig);

	window.dpIntroTourPublicConfig.disableOnLoad = processDisableOnLoadCookie('dpit-close-reload');
	DpitUserVisitCounter.processVisit(window.dpIntroTourPublicConfig);
	if (window.dpIntroTourPublicConfig.tours && window.dpIntroTourPublicConfig.tours.length) {
		window.dpIntroTourPublicConfig.tours.forEach((tourCfg) => {
			new Dpit(window.dpIntroTourPublicConfig, tourCfg);
		});
	}
});
