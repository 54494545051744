import { updateCssVar } from './dpitUtilsInc';
export default class BrowserSupport {

	static vHFix(element = document.documentElement) {
		let vh = window.innerHeight * 0.01;
		updateCssVar('--dpit-vh', `${vh}px`, element);
	}

	static isSupported = () => {
		const isSupported = !BrowserSupport.isIE();
		if (!isSupported) console.log('DP Intro Tours: Browser is not supported');
		return isSupported;
	};

	static isIE() {
		const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
		const msie = ua.indexOf('MSIE '); // IE 10 or older
		const trident = ua.indexOf('Trident/'); //IE 11
		return (msie > 0 || trident > 0);
	}

}